interface GameCrossbowEvents {
  leftSwing?: () => void;
  centerSwing?: () => void;
  rightSwing?: () => void;
}

interface GameCrossbowOptions {
  amplitude?: number;
  duration?: number;
  events?: GameCrossbowEvents;
}

class GameCrossbow {
  private readonly square: HTMLElement | null;
  private amplitude: number = 30;
  private duration: number = 2000;
  private angle: number = 0;
  private direction: number = 1;
  private events: GameCrossbowEvents = {};
  private isCooldown: boolean = false;
  private isAnimationRunning: boolean = false;
  private prevTimestamp: number | null = null;

  constructor(selector: string, options: GameCrossbowOptions = {}) {
    this.square = document.querySelector<HTMLElement>(selector);

    if (!this.square) return;

    this.amplitude = options.amplitude ?? this.amplitude;
    this.duration = options.duration ?? this.duration;
    this.events = options.events ?? this.events;
  }

  init(): void {
    if (!this.square) return;

    this.startSwing();
    this.addClickHandler();
    this.addVisibilityChangeHandler();
  }

  private startSwing(): void {
    if (!this.square || this.isAnimationRunning) return;

    this.isAnimationRunning = true;
    this.prevTimestamp = null;

    // Сбрасываем угол и направление при запуске анимации
    this.angle = 0;
    this.direction = 1;

    requestAnimationFrame(this.animate);
  }

  private stopSwing(): void {
    this.isAnimationRunning = false;
    this.prevTimestamp = null;
  }

  private animate = (): void => {
    if (!this.isAnimationRunning) return;

    const currentTime = performance.now();

    if (this.prevTimestamp === null) {
      this.prevTimestamp = currentTime;
    }

    let deltaTime = currentTime - this.prevTimestamp;
    this.prevTimestamp = currentTime;

    // Ограничиваем deltaTime, чтобы предотвратить резкие скачки
    const maxDeltaTime = 100; // миллисекунды
    deltaTime = Math.min(deltaTime, maxDeltaTime);

    const step = (this.amplitude / this.duration) * deltaTime * this.direction;

    this.angle += step;

    if (this.angle >= this.amplitude || this.angle <= -this.amplitude) {
      this.direction *= -1;
      this.angle = Math.max(Math.min(this.angle, this.amplitude), -this.amplitude);
    }

    this.square!.style.transform = `rotate(${this.angle}deg)`;

    requestAnimationFrame(this.animate);
  };

  private addClickHandler(): void {
    if (!this.square) return;

    this.square.addEventListener('click', () => {
      if (this.isCooldown) return;

      this.isCooldown = true;

      document.body.classList.add('is-arrow-shot');

      setTimeout(() => {
        document.body.classList.remove('is-arrow-shot');

        this.isCooldown = false;
      }, 2000);

      if (this.angle <= -this.amplitude / 3) {
        this.triggerEvent('leftSwing');
      } else if (this.angle > -this.amplitude / 3 && this.angle < this.amplitude / 3) {
        this.triggerEvent('centerSwing');
      } else {
        this.triggerEvent('rightSwing');
      }
    });
  }

  private addVisibilityChangeHandler(): void {
    document.addEventListener('visibilitychange', () => {
      if (document.hidden) {
        this.stopSwing();
      } else {
        this.startSwing();
      }
    });
  }

  private triggerEvent(eventName: keyof GameCrossbowEvents): void {
    const eventHandler = this.events[eventName];

    if (eventHandler && typeof eventHandler === 'function') {
      eventHandler();
    }
  }
}

export default GameCrossbow;
