type GameTracerOptions = {
  container?: HTMLElement;
  elementColor?: string;
  trailColor?: string;
  speed?: number;
};

type TrailElement = {
  x: number;
  y: number;
  length: number;
  width: number;
  speed: number;
  angle: number;
  trail: Array<{ x: number; y: number }>;
};

class GameTracer {
  private container: HTMLElement;
  private tracerElement: HTMLDivElement;
  private canvas: HTMLCanvasElement;
  private ctx!: CanvasRenderingContext2D;
  private width: number = 0;
  private height: number = 0;
  private trail: TrailElement[] = [];
  private elementColor: string = '';
  private trailColor: string = '';
  private speed: number = 0;
  private resizeObserver: ResizeObserver | null = null;
  private debouncedUpdateCanvasSize!: () => void;

  constructor(options: GameTracerOptions = {}) {
    this.container = options.container || document.body;

    this.tracerElement = document.createElement('div');
    this.tracerElement.classList.add('game-tracer');
    this.container.appendChild(this.tracerElement);

    this.canvas = document.createElement('canvas');

    const context = this.canvas.getContext('2d');

    if (!context) {
      return;
    }

    this.ctx = context;
    this.tracerElement.appendChild(this.canvas);

    this.width = this.tracerElement.clientWidth || window.innerWidth;
    this.height = this.tracerElement.clientHeight || window.innerHeight;

    const dpr = window.devicePixelRatio || 1;

    this.elementColor = this.normalizeColor(options.elementColor || 'rgb(0, 255, 0)');
    this.trailColor = this.normalizeColor(options.trailColor || 'rgb(255, 255, 0)');
    this.speed = (options.speed || 10) * dpr;

    this.debouncedUpdateCanvasSize = this.debounce(() => this.updateCanvasSize(), 300);
  }

  private normalizeColor(color: string): string {
    return color;
  }

  init(): void {
    if (!this.ctx) return;

    this.updateCanvasSize();
    this.observeResize();
    this.animate();
  }

  private updateCanvasSize(): void {
    const dpr = window.devicePixelRatio || 1;

    this.width = this.tracerElement.clientWidth;
    this.height = this.tracerElement.clientHeight;

    this.canvas.width = this.width * dpr;
    this.canvas.height = this.height * dpr;

    this.canvas.style.width = `${this.width}px`;
    this.canvas.style.height = `${this.height}px`;

    this.ctx.scale(dpr, dpr);
  }

  private debounce(func: (...args: unknown[]) => void, wait: number): () => void {
    let timeout: number;

    return (...args: unknown[]) => {
      clearTimeout(timeout);

      timeout = window.setTimeout(() => func.apply(this, args), wait);
    };
  }

  private observeResize(): void {
    this.resizeObserver = new ResizeObserver(() => {
      this.debouncedUpdateCanvasSize();
    });

    this.resizeObserver.observe(this.tracerElement);
  }

  private createElement(direction: 'left' | 'center' | 'right'): void {
    const element: TrailElement = {
      x: this.width / 2,
      y: this.height,
      length: 40,
      width: 5,
      speed: this.speed,
      angle: this.getAngle(direction),
      trail: [],
    };

    this.trail.push(element);
  }

  private getAngle(direction: 'left' | 'center' | 'right'): number {
    if (direction === 'left') {
      return Math.atan2(-this.height, -this.width / 2);
    }

    if (direction === 'center') {
      return Math.atan2(-this.height, 0);
    }

    if (direction === 'right') {
      return Math.atan2(-this.height, this.width / 2);
    }

    return 0;
  }

  flyLeft(): void {
    this.createElement('left');
  }

  flyCenter(): void {
    this.createElement('center');
  }

  flyRight(): void {
    this.createElement('right');
  }

  private drawElement(element: TrailElement): void {
    const { ctx } = this;

    element.trail.unshift({ x: element.x, y: element.y });

    if (element.trail.length > 50) element.trail.pop();

    element.trail.forEach((pos, index) => {
      const alpha = 1 - index / element.trail.length;
      ctx.save();
      ctx.translate(pos.x, pos.y);
      ctx.rotate(element.angle + Math.PI / 2);
      ctx.fillStyle = this.addAlphaToColor(this.trailColor, alpha);
      ctx.fillRect(-element.width / 2, -element.length / 2, element.width, element.length);
      ctx.restore();
    });

    ctx.save();
    ctx.translate(element.x, element.y);
    ctx.rotate(element.angle + Math.PI / 2);
    ctx.fillStyle = this.elementColor;
    ctx.fillRect(-element.width / 2, -element.length / 2, element.width, element.length);
    ctx.restore();
  }

  private addAlphaToColor(color: string, alpha: number): string {
    const rgbaMatch = color.match(/rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*([\d.]+))?\)/);

    if (!rgbaMatch) {
      return color;
    }

    const [_, r, g, b, existingAlpha] = rgbaMatch;
    const baseAlpha = existingAlpha !== undefined ? parseFloat(existingAlpha) : 1;
    const combinedAlpha = alpha * baseAlpha;

    return `rgba(${r}, ${g}, ${b}, ${combinedAlpha})`;
  }

  private updateElement(element: TrailElement): void {
    element.x += Math.cos(element.angle) * element.speed;
    element.y += Math.sin(element.angle) * element.speed;

    if (element.y + element.length < 0 || element.x < 0 || element.x > this.width) {
      const index = this.trail.indexOf(element);

      if (index > -1) this.trail.splice(index, 1);
    }
  }

  private animate(): void {
    if (!this.ctx) return;

    const { ctx } = this;

    ctx.clearRect(0, 0, this.width, this.height);

    this.trail.forEach((element) => {
      this.updateElement(element);
      this.drawElement(element);
    });

    requestAnimationFrame(() => this.animate());
  }
}

export default GameTracer;
