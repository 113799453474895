type CardKeys = 'one' | 'two' | 'three';

interface GameCharactersInit {
  flipCardOne: () => void;
  flipCardTwo: () => void;
  flipCardThree: () => void;
  flipAllCards: () => void;
}

class GameCharacters {
  private container: HTMLElement | null;
  private cardFlipCounts: Record<CardKeys, number>;
  private cards: Record<CardKeys, HTMLElement | null>;

  constructor(containerSelector: string) {
    this.container = document.querySelector(containerSelector) || null;

    this.cardFlipCounts = {
      one: 0,
      two: 0,
      three: 0,
    };

    this.cards = {
      one: this.getCardElement('one'),
      two: this.getCardElement('two'),
      three: this.getCardElement('three'),
    };
  }

  private getCardElement(cardKey: CardKeys): HTMLElement | null {
    if (!this.container) return null;
    return this.container.querySelector<HTMLElement>(`[data-ghoul-card-${cardKey}]`);
  }

  public init(): GameCharactersInit {
    return {
      flipCardOne: () => this.flipCard('one'),
      flipCardTwo: () => this.flipCard('two'),
      flipCardThree: () => this.flipCard('three'),
      flipAllCards: () => this.flipAllCards(),
    };
  }

  private flipCard(cardKey: CardKeys): void {
    const cardElement = this.cards[cardKey];
    if (!cardElement) return;

    const scaleElement = cardElement.querySelector<HTMLElement>('.ghoul-scale');
    const flipElement = cardElement.querySelector<HTMLElement>('.ghoul-flip');

    if (!scaleElement || !flipElement) return;

    this.cardFlipCounts[cardKey] += 5;

    flipElement.style.setProperty('--rotation-angle', `${this.cardFlipCounts[cardKey] * 180}deg`);

    scaleElement.classList.add('scale-up');

    setTimeout(() => {
      scaleElement.classList.remove('scale-up');
    }, 1000);

    this.updateZIndex(cardElement);
  }

  private updateZIndex(activeCard: HTMLElement): void {
    Object.values(this.cards).forEach((card) => {
      if (card) {
        card.style.zIndex = card === activeCard ? '1' : '';
      }
    });
  }

  private flipAllCards(): void {
    Object.keys(this.cards).forEach((cardKey) => this.flipCard(cardKey as CardKeys));
  }
}

export default GameCharacters;
