import GameCharacters from './utils/GameCharacters';
import GameCrossbow from './utils/GameCrossbow';
import GameTracer from './utils/GameTracer';

interface GameControls {
  flipCardOne: () => void;
  flipCardTwo: () => void;
  flipCardThree: () => void;
}

class App {
  private gameCharacters: GameCharacters | null = null;
  private gameCrossbow: GameCrossbow | null = null;
  private gameTracer: GameTracer | null = null;

  constructor() {}

  async init(): Promise<void> {
    const controls = await this.initializeGameCharacters();

    this.initializeGameTracer();
    this.initializeGameCrossbow(controls);
  }

  private async initializeGameCharacters(): Promise<GameControls> {
    this.gameCharacters = new GameCharacters('.game-characters');

    return this.gameCharacters.init();
  }

  private initializeGameTracer(): void {
    this.gameTracer = new GameTracer({
      elementColor: 'rgb(238, 173, 3)',
      trailColor: 'rgba(64, 73, 105, 0.25)',
      speed: 7,
    });

    this.gameTracer.init();
  }

  private initializeGameCrossbow(controls: GameControls): void {
    this.gameCrossbow = new GameCrossbow('.game-crossbow__metronome', {
      amplitude: 50,
      duration: 400,
      events: {
        leftSwing: () => this.handleCrossbowSwing('left', controls.flipCardOne),
        centerSwing: () => this.handleCrossbowSwing('center', controls.flipCardTwo),
        rightSwing: () => this.handleCrossbowSwing('right', controls.flipCardThree),
      },
    });

    this.gameCrossbow.init();
  }

  private handleCrossbowSwing(direction: string, flipCardCallback: () => void): void {
    switch (direction) {
      case 'left':
        this.gameTracer?.flyLeft();
        break;
      case 'center':
        this.gameTracer?.flyCenter();
        break;
      case 'right':
        this.gameTracer?.flyRight();
        break;
      default:
        break;
    }

    setTimeout(() => flipCardCallback(), 400);
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const app = new App();

  app.init().then();
});
